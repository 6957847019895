import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-8 text-center">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <iframe 
                src="/privacy-policy.pdf"
                width="100%" 
                height="800px"
                title="Privacy Policy"
                className="border rounded-lg shadow-md"
            />
        </div>
    );
};

export default PrivacyPolicy;
