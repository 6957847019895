import React from 'react';
import loop from '../images/certificates/ICO_Logo_Blue.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Certification = () => {
    return (
        <div className="mt-4 bg-gray-100 justify-center">
            <section>
                <div className="my-0 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Certifications</h2>
                    </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div>
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                            <img src={loop} alt="ico" />                           
                        </div>           
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Certification;