import React from 'react';

const RegistrationCertificate = () => {
    return (
        <div className="container mx-auto p-8 text-center">
            <h1 className="text-3xl font-bold mb-6">Data Protection Certificate</h1>
            <iframe 
                src="/registration-certificate.pdf" 
                width="100%" 
                height="800px"
                title="Registration Certificate"
                className="border rounded-lg shadow-md"
            />
        </div>
    );
};

export default RegistrationCertificate;
